import React from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import Grid from '@material-ui/core/Grid';

import { api } from "./api/api";

// Material Table
import MaterialTable from 'material-table';
import { AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight,
Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage,
Remove, SaveAlt, Search, ViewColumn, NoEncryption, Visibility, VisibilityOutlined } from "@material-ui/icons";
import { NONAME } from 'dns';
import moment from 'moment';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
// Material Table


//import rows from './customerdata.json';
const userCookie = Cookies.get('iscpUser');
const user = userCookie ? JSON.parse(userCookie) : "";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

//Get Data Function
function getUnloggedDevicePrescriptions(){
  return new Promise(function(resolve, reject){
    try {
      let curCookie = userCookie ? userCookie : Cookies.get('iscpUser');
      console.log("curCookie: ", curCookie);
      let curUser = user ? user : JSON.parse(curCookie);
      console.log("User making request: ", curUser);
      let resourceURI = "unused_prescriptions"
      /*if (reportStartDate) {
        resourceURI += '?startDate=' + encodeURIComponent(reportStartDate);
        if (reportEndDate){
          resourceURI += '&endDate=' + encodeURIComponent(reportEndDate);
        }
      } else {
        if (reportEndDate){
          resourceURI += '?endDate=' + encodeURIComponent(reportEndDate);
        }
      }*/
      console.log("The requested resource: ", resourceURI);
      const request = {
        //resource: "customer_data",
        resource: resourceURI,
        method: "get"
      };
      api(request, curUser).then(response => {
        if (response.status === 200){
        console.log("Response: ", response.data.prescriptions_without_logs);
        resolve(response.data.prescriptions_without_logs);
      } else {
        console.log("Login error: ", response);
        reject(response);
      }
      });
    } catch (error) {
      console.log("Error: ", error);
      reject(error);
    }
  })
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  gridRoot: {
    flexGrow: 1,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    maxHeight: 500,
    overflowX: 'auto',
  },
  hidden: {
    display: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: 'white'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function DeviceTracking(props) {
  const [unloggedDevicePrescriptions, setUnloggedDevicePrescriptions] = React.useState([]);
  const [fetchingData, setFetchingData] = React.useState(true);
  const classes = useStyles2();
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);
  //const [isFetchingData, setFetchingData] = React.useState(true);
  const [checked, setChecked] = React.useState();

  //React.useEffect(() => {
  //  console.log('componentDidMount');
  //  //Possibly do stuff to get prescriptions?!?
  //  /*const patients = props.deviceLogs.map( log => {
  //    return log.patient_identifier
  //  });*/
  //}, [props.deviceLogs]);

  React.useEffect(() => {
    console.log('componentDidMount');
    getUnloggedDevicePrescriptions()
      .then(data => {
        console.log("Device Prescriptions with no Logs: ", data);
          let processedData = data.map( function(entry) {
            let processedEntry = entry;
            /*if (!event.parselogentry.includes('Delivery// Success')) {
                processedEvent.pillcount = '-';
            }*/
            let theDate = new Date(entry.created_at);
            let eventdate = theDate.toLocaleDateString([], {month: '2-digit', day: '2-digit', year: 'numeric'});
            //let eventtime = theDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false});
            processedEntry.date_created = eventdate;
            //processedEvent.eventtime = eventtime;
            let mac_id = entry.device_mac_id.substr(entry.device_mac_id.length - 5);
            processedEntry.mac_id = mac_id;
            return processedEntry;
          });
          /*setDeviceLogs(processedData);
          const patientIds = processedData.map(getPatientIdentifier).filter( onlyUnique);
          setPatientIds(patientIds);
          setPatientIdsForReport(patientIds);
          setSelectedPatients(patientIds);
          console.log("Patient IDs: ", patientIds);*/
          setUnloggedDevicePrescriptions(processedData);
          setFetchingData(false);
        })
  }, []);

  
  const handleSelectionProps = rowData => {
    return {
      disabled:
        checked && checked.length >= 5 && !rowData.tableData.checked
          ? true
          : false
    };
  };

  return (
      <div>
        <MaterialTable
          icons={tableIcons}
          columns={[
            //{ title: "Log Entry Id", field: "log_entry_id", type: "numeric" },
            { title: "Patient Group", field: "patient_group" },
            { title: "Patient ID", field: "patient_identifier" },
            { title: "Prescription Ref #", field: "prescription_id" },
            { title: "Prescription Date", field: "date_created", type: "date" },
            { title: "MAC", field: "mac_id" }
          ]}
          data={unloggedDevicePrescriptions}
          detailPanel={[
            {
              tooltip: 'Click to view/hide prescription details.',
              render: rowData => {
                return (
                <div
                  className={classes.gridRoot}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            Type:{rowData.prescription_type}<br />
                            Pill per day: {rowData.pills_per_dose * rowData.doses_per_day}<br />
                            Pill per dose: {rowData.pills_per_dose}<br />
                            Doses per day: {rowData.doses_per_day}<br />
                            Minutes between doses: {rowData.lockoutperiod}<br />
                          </Grid>
                          <Grid item xs={4}>
                              Pill Count: {rowData.prescription_pill_count}<br /> 
                              <br />
                              Device: {rowData.device_label}<br />
                          </Grid>
                          <Grid item xs={4}>
                              Medication<br />
                              Name:{rowData.prescription_name}<br />
                              Strength:{rowData.prescription_strength}<br />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              )}
            }
          ]}
          title="Device Tracking"
          options={{
            search: false,
            filtering: true,
            pageSize: 10,
            headerStyle: {
              textAlign: 'center'
            },
            rowStyle: {
              fontSize: 10,
            },
            exportButton: true,
            exportAllData: true,
            //selection: true,
            //showSelectAllCheckbox: false,
            //selectionProps: handleSelectionProps
          }}
          onSelectionChange={rows => {
            setChecked(rows);
            //const patientIds = rows.map(getPatientIdentifier);
            //props.patientSelectionHandler(patientIds);
          }}
          /*onSelectionChange={(rows, row) => {
            if (rows.length < 6){
              console.log('All you selected ', rows);
              console.log('You selected ', row.patient_identifier);
            } else {
              alert('You can only select 5 patients.');
              row.tableData.checked = false;
            }
          }}*/
          isLoading={fetchingData}
        />
        <br /> 
        
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.hidden}
          onClick={() => {
            //getPrescription()
            //.then(data => {
            //  console.log("DATA! ", data);
            //})
          }}
        >
          Get Data
        </Button>
      </div>
  );
}