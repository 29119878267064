// Normalizes login/signup response data for easy usage, storage
/*export const normalizeUser = response => {
    console.log("normalizeUser: ", response);
    return {
      ...response.data,
      ...{
        token: response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid
      }
    };
  };*/
// Normalizes login/signup response data for easy usage, storage
export const normalizeUser = response => {
    return {
      ...response.data.data,
      ...{
        token: response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid
      }
    };
  };
  
  // API Error Handler
  export const handleError = payload => {
    console.log("API ERROR", payload);
    if (payload) {
      if (payload.data && payload.data.errors) {
        if (payload.data.errors.full_messages) {
          return payload.data.errors.full_messages[0];
        } else {
          return payload.data.errors[0];
        }
      }
      return payload.statusText;
    }
    return "An error occured, plese contact support for assistance.";
  };
  
  // Simple Merge Function
  // Takes two objects and combines them together using a shallow merge
  export const merge = (start, add) => {
    return { ...start, ...add };
  };
  
  // Returns a mini set of a larger object
  export const miniset = (args, dataset) => {
    const obj = {};
    args.forEach(arg => (obj[arg] = dataset[arg]));
    return obj;
  };
  
  // Converts Minutes->Seconds & Seconds->Minutes
  export const toSeconds = time => Number(time) * 60;
  export const toMinutes = time => Number(time) / 60;
  
  
  // Date Helper
  export const dateInBytes = () => {
    const date = new Date();
    let byteArr = [];
    // Build Year
    const year = date.getFullYear().toString(16);
    const year2 = `0x${year.slice(-2)}`;
    const yTrim = year.substring(0, year.length - 2);
    const year1 = yTrim.length > 1 ? `0x${yTrim}` : `0x0${yTrim}`;
    byteArr.push(year2);
    byteArr.push(year1);
    // Build Month & Day
    byteArr.push(splitNum(date.getMonth() + 1));
    byteArr.push(splitNum(date.getDate()));
    // Build Hours, Minutes, Seconds
    byteArr.push(splitNum(date.getHours()));
    byteArr.push(splitNum(date.getMinutes()));
    byteArr.push(splitNum(date.getSeconds()));
    // Concat Empty Byte
    byteArr = byteArr.concat(['0x00']);
    return byteArr;
  }
  
  const splitNum = (num, year = false) => {
    const hex = num.toString(16);
    return hex.length > 1 ? `0x${hex}` : `0x0${hex}`;
  }