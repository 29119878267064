import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../IS_Brand_color.png';
import Copyright from '../Copyright';
import OutsideFooterLinks from '../OutsideFooterLinks';


const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
  }));

export default function Privacy() {
    const classes = useStyles();
  return (
    <Container component="main" maxWidth="s">
        <CssBaseline />
        <div className={classes.paper}>
            <Typography variant="h3" component="h1" gutterBottom>
                <img src={logo} alt="Intent Solutions" width={'330'} />
            </Typography>
            <Typography variant="h5" component="h2">
                Customer Web Portal
            </Typography>
            <br />
            <div>
                <div class="intro">
                    <h1>Privacy Policy</h1>
                    <div class="content justify">
                        <strong>Introduction</strong>
                        <br/>
                        <p>
                            INTENT SOLUTIONS, Inc., a Delaware corporation (“Intent Solutions”, “us”, “our” or “we”) respects your privacy and is committed to protecting it through our compliance with this policy (“Privacy Policy”).
                        </p>
                        <p>This Privacy Policy describes the personal information Intent Solutions collects about you, and how we use and share that information. This policy applies to personal information we may collect when you access and use the Intent Solutions website, applications, products, devices, or services, whether you are a patient, a healthcare provider, or are just browsing the same (collectively, the “Services”); or when you otherwise interact with us, including through e-mail, text and other electronic messages. It does not apply to information collected by (i) us offline or through any other means, or (ii) any third party, including through any application or content (including advertising) that may link to or be accessible from our Services.
                        </p>
                        <p>This Privacy Policy refers to personal information which is information that can be associated with a specific person and used to identify that person. Personal information does not include information that has been anonymized so that it does not identify a specific user, which may be used for any legal purpose.
                        </p>
                        This Privacy Policy covers the following topics:
                        <ol>
                            <li>Updates to the Privacy Policy</li>
                            <li>Sources of Information About You</li>
                            <li>Our Use of Information</li>
                            <li>Sharing of Information</li>
                            <li>Updating Information</li>
                            <li>Our Opt-in / Opt-out Policy</li>
                            <li>Links to Other Web Sites; Third Party Applications</li>
                            <li>International Transfer</li>
                            <li>Children’s Privacy</li>
                            <li>Security</li>
                            <li>“Do Not Track” Disclosures</li>
                            <li>Contact Information</li>
                            <li>Effective Date</li>
                        </ol>
                        <p>PLEASE REVIEW THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS CAREFULLY. BY SUBMITTING YOUR INFORMATION TO US AND BY USING THE SERVICES, WE WILL ASSUME THAT YOU HAVE READ, UNDERSTOOD, AND CONSENT TO THE AGREEMENT, WHICH INCLUDES THIS PRIVACY POLICY, THE TERMS AND CONDITIONS, AND ALL OTHER APPLICABLE INTENT SOLUTIONS RULES AND POLICIES. WE ALSO ASSUME THAT YOU CONSENT TO OUR USE OF YOUR INFORMATION AS DESCRIBED IN THE AGREEMENT. If you do not consent, you should not access or use the Services.
                        </p>
                        <ol>
                            <li><span class="section">Updates to the Privacy Policy</span>
                                <br/> We may update, amend, revise or otherwise change this Privacy Policy from time to time. If we make changes, we will notify you by posting the updated policy and revising the “Effective Date” above. Your continued use of the Services constitutes your acceptance of the then-effective Privacy Policy. We encourage you to periodically review our Privacy Policy to be sure you are familiar with the most current version.
                            </li>
                            <li><span class="section">Sources of Information About You</span>
                                <br/>
                                <p>We obtain information about you from a variety of sources, including you, your interaction with Intent Solutions, and third parties. Such information may include your name, postal address, e-mail address, and telephone number, among other personally identifiable information.
                                </p>
                                <blockquote>Information you provide to us: If you use certain Services, you may be required to provide us certain information.
                                    <ul>
                                        <li>Information that you provide by filling in forms on our website. This includes information provided at the time of registering to use our Services, subscribing to our Services, posting material or requesting further services. </li>
                                        <li>We may also ask you for information when you report a problem with our Services.</li>
                                        <li>Records and copies of your correspondence (including e-mail addresses), if you contact us.</li>
                                        <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                                        <li>Details of transactions you carry out through our Services and of the fulfillment of your orders.</li>
                                        <li>Your search queries on our website or through our Services.</li>
                                    </ul>
                                </blockquote>
                                <blockquote>Information we collect from your use of the Services and other interactions you have with Intent Solutions:
                                    <p>We may collect your usage information about how you use the Services, including your access times, location, browser types and language, and Internet Protocol addresses. We may collect device-specific information when you access our Services, including your hardware model, operating system and version, unique device identifiers, mobile network information, and information about the device's interaction with our Services. In order to use certain Services, we may collect biometrics, health data, and other usage data regarding your interaction with our Services.
                                    </p>
                                    <p>The information we collect automatically is statistical data and may or may not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve and to deliver better and more personalized Services, including by enabling us to:
                                        <ul>
                                            <li>Estimate our audience size and usage patterns.</li>
                                            <li>Store information about your preferences, allowing us to customize our Services according to your individual interests.</li>
                                            <li>Speed up your searches.</li>
                                            <li>Recognize you when you return to our Services.</li>
                                        </ul>
                                        <p>The technologies we use for this automatic data collection may include:
                                            <p>
                                                <ul>
                                                    <li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services. </li>
                                                    <li>Flash Cookies. Certain features of our Services may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from and on our Services. Flash cookies are not managed by the same browser settings as are used for browser cookies.</li>
                                                    <li>Web Beacons. Pages of the Services or our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit Intent Solutions, for example, to count users who have visited those pages or opened an e-mail and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                                                </ul>
                                            </p>
                                        </p>
                                    </p>
                                </blockquote>
                                <blockquote>Information We Collect From Other Sources. We may also collect information about you from third parties, including but not limited to third-party verification services, credit bureaus, mailing list providers, and publicly available sources. This information may include your Social Security number.</blockquote>
                            </li>
                            <li><span class="section">Our Use of Information</span>
                                <br/>
                                <p>We use information about you to provide, maintain, personalize and improve our Services and to deliver the information and support your requests, including receipts, technical notices, security alerts, and support and administrative messages.
                                </p>
                                <p>We may use information we collect to send you news and information about our Services and to communicate with you about products, services, contests, promotions, incentives, and rewards offered by us and select partners. You may opt out of receiving promotional emails or text messages from Intent Solutions by following the instructions in those emails or text messages. If you opt out, we may still send you non-promotional communications, such as messages about your account or our ongoing business relations.
                                </p>
                                <p>We may also use information about you (i) to track and analyze trends and usage in connection with our Services; (ii) to process and deliver contest and promotion entries and rewards; (iii) to protect our rights or property; (iv) to compare information for accuracy; (v) to verify your identity; (vi) to investigate and prevent fraud or other illegal activities; and (vii) for any other purpose disclosed to you in connection with our Services.
                                </p>
                                <p>Additionally, we use information that we collect about you or that you provide to us, including any personal information:
                                </p>
                                <ul>
                                    <li>To provide you with information, products or services that you request from us.</li>
                                    <li>To fulfill any other purpose for which you provide it.</li>
                                    <li>To provide you with notices about your account/subscription, including expiration and renewal notices.</li>
                                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                                    <li>To notify you about changes to our Services or any products or services we offer or provide through them.</li>
                                    <li>In any other way we may describe when you provide the information.</li>
                                    <li>For any other purpose with your consent.</li>
                                </ul>
                                <p>We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences.
                                </p>
                                <p>We may use third-party service providers to process and store personal information in the United States and other countries.</p>
                            </li>
                            <li><span class="section">Sharing of Information</span>
                                <br/>
                                <p>We may share personal information about you as follows:</p>
                                <ul>
                                    <li>To your doctor, healthcare provider, pharmacist and/or clinician, as verified by you.</li>
                                    <li>To our subsidiaries and affiliates.</li>
                                    <li>To contractors, service providers and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                                    <li>To comply with any court order, law or legal process, including to respond to any government or regulatory request.</li>
                                    <li>To enforce or apply our terms of service, available at <a href="http://tadportal.com/license"> http://tadportal.com/license</a>, and other agreements, including for billing and collection purposes.</li>
                                    <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Intent Solutions, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Intent Solutions' assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by Intent Solutions about our users is among the assets transferred.</li>
                                    <li>To fulfill the purpose for which you provide it.</li>
                                    <li>With third parties that run advertising campaigns, contests, special offers, or other events or activities in connection with our Services.</li>
                                    <li>For any lawful purpose, including, without limitation, selling your data to third parties.</li>
                                    <li>With your consent or direction.</li>
                                </ul>
                                We may also share non-personally identifiable information (aggregated or otherwise) with third parties for any reason.
                            </li>
                            <li><span class="section">Updating Information</span>
                                <br/> You may update or correct information about yourself by emailing us at support@intentsolutions.com or by accessing your [“My Account”] page. If you completely delete all such information, then your account may become deactivated. We may retain an archived copy of your records as required by law or for legitimate business purposes.
                            </li>
                            <li><span class="section">Our Opt-in / Opt-out Policy</span>
                                <br/> By providing your contact information for the Services, you agree that we may contact you to provide you with any service related notices, or to provide you with information about Intent Solutions products, services, events, invitations, or related educational information. For purposes of this Privacy Policy, "opt-in" is generally defined as any affirmative action by an end user to submit or receive information, as the case may be. We currently provide the following “Opt-Out Opportunities”: (i) At any time, you can follow a link provided in offers, newsletters or other email messages (except for e-commerce confirmation or service notice emails) received from Intent Solutions to unsubscribe from the Service; or (ii) At any time, you can contact us through support@intentsolutions.com or the address or telephone number provided below to unsubscribe from the Service and opt-out of our right per your consent under the terms of this Privacy Policy to share your personal information. Notwithstanding anything else in this Privacy Policy, please note that we always reserve the right to contact you in the event of a change in our terms and conditions, including this Privacy Policy, or to provide you with any Service-related notices. California Users. In addition to the ability to utilize the Opt-Out Opportunities described above, California Users may have the right to request certain disclosure information annually from Intent Solutions. Such disclosure requests should be directed to:
                                <p>Intent Solutions
                                    <br/>730 Peachtree Street NE
                                    <br/>Suite 550
                                    <br/>Atlanta, GA 30308</p> For more information, please reference the Cal. Civ. Code § 1798.83, available at http://oag.ca.gov/privacy/privacy-laws. In addition, if you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
                            </li>
                            <li><span class="section">Links to Other Web Sites; Third Party Applications</span>
                                <br/> Intent Solutions is not responsible for any practices employed by websites linked to or from our website, nor their information or content. Please remember that when you use a link to go from our website to another website, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website is subject to that website’s own rules and policies. Please read over those rules and policies before proceeding. Additionally, some content or applications, including advertisements, on or through the Services are served by third-parties, including advertisers, ad networks and servers, content providers and application providers. These third parties may use cookies, alone or in conjunction with web beacons or other tracking technologies, to collect information about you when you use our Services. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. Intent Solutions also operates and maintains social media networking pages on websites such as Facebook, Twitter, and LinkedIn. We may, from time to time, import comments and posts from these third-party websites to the Services. If you post to any of our social networking pages on third-party websites, you are providing information to the public and do so at your own risk. Further, we may use these postings on our Services without notice or compensation to you. Please visit the privacy policies of any third party websites before posting to our pages there.
                            </li>
                            <li><span class="section">International Transfer</span>
                                <br/> By accessing and using the Services, you acknowledge and agree that Intent Solutions controls and operates all parts of the Services from its offices in the United States of America and that the Services is intended for use by end users located in the United States of America. Unless expressly stated to the contrary, Intent Solutions makes no representation that the Services is appropriate or will be available for use in other locations. If you access the Services from outside the United States, you acknowledge and agree that your information may be transferred to and maintained on computers and servers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Your consent to the Agreement followed by your submission of such information represents your agreement to the transfer of such information to the United States and the collection, use, and disclosure of your information in accordance with United States law and our Privacy Policy.
                            </li>
                            <li><span class="section">Children's Privacy</span>
                                <br/> The Children’s Online Privacy Protection Act was created to protect children under the age of 13 from unsuspecting acts or practices in conjunction with collecting, using, and disclosing any information about them. Our Services are not intended for anyone under the age of 13. Any access to or use of the Services by anyone under the age of 13 is unauthorized, unlicensed, and in violation of the Agreement. If you are under 13, do not use or provide any information on or through our Services. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you are a parent or guardian or otherwise believe we might have any information from or about a child under 13, please contact us so that we can delete the child's information. The Services will never knowingly accept, collect, maintain or use any information from a child under the age of 13. If a child whom we know to be under the age of 13 sends personal information to us online, we will only use that information to respond directly to that child or notify parents.
                            </li>
                            <li><span class="section">Security</span>
                                <br/> We safeguard the security of your personal information with industry-standard physical, electronic, and administrative procedures. The safety and security of your personal information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to or through our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on or within the Services. We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored “personal data” (as defined in applicable state statutes on security breach notification) to you via email or conspicuous posting through the Services in the most expedient time possible and without unreasonable delay, as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system. By using our Services and disclosing personally identifiable information that may be protected by state or federal privacy and security laws, you are waiving your privacy rights to the released information. You are responsible to use reasonable safeguards to protect your personal information.
                            </li>
                            <li><span class="section">“Do Not Track” Disclosures</span>
                                <br/> Some state laws require us to indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. We adhere to the standards set out in this Privacy Policy and do not monitor or follow any Do Not Track browser requests.
                            </li>
                            <li><span class="section">Contact Information</span>
                                <br/> Please contact us with any questions or concerns regarding our Privacy Policy at:
                                <a email="support@intentsolutions.com">support@intentsolutions.com</a>
                            </li>
                            <li><span class="section">Effective Date</span>
                                <br/> This Privacy Policy was last updated on 3/19/2018.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <OutsideFooterLinks /><br />
        <Copyright />
    </Container>
  );
}