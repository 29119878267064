import React from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import Grid from '@material-ui/core/Grid';

import { api } from "./api/api";

import Chart from 'chart.js';

import moment from 'moment';
import lodash from 'lodash';

let myChart;

const userCookie = Cookies.get('iscpUser');
const user = userCookie ? JSON.parse(userCookie) : "";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  gridRoot: {
    flexGrow: 1,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    maxHeight: 500,
    overflowX: 'auto',
  },
  hidden: {
    display: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: 'white'
  }
}));

export default function DeviceLogChart(props) {
  let chartRef = React.createRef();
  const classes = useStyles2();
  const [dataSeries, setDataSeries] = React.useState({});
  const [xTicks, setXTicks] = React.useState([]);
  const [xAxisLablesList, setXAxisLabelsList] = React.useState([]);
  
  React.useEffect(() => {
    console.log('chartComponent recived data update... rebuilding chart');
    setDataSeries(
      props.dataSeries
    );
    buildChart();
  }, [props.dataSeries]);

  const buildChart = () => {
    const myChartRef = chartRef.current.getContext("2d");
    
    if (typeof myChart !== "undefined") myChart.destroy();

    myChart = new Chart(myChartRef, {
      type: 'scatter',
      data: {
        datasets: props.dataSeries.dataSets,
      },
      options: {
          title: {
            display: true,
            text: 'Dispensing History',
            fontStyle: 'bold',
            fontSize: 18
          },
          scales: {
              xAxes: [{
                  type: 'category',
                  labels: props.xAxisLablesList
              }],
              yAxes: [{
                gridlines: {
                  display: true,
                },
                ticks: {
                  stepSize: 3600000,
                  min: 946702800000,
                  max: 946789200000,
                  beginAtZero: false,
                  callback: value => {
                    let date = moment(value);
                    if(date.diff(moment('2000-01-01 23:59:59'), 'minutes') === 0) {
                      return null;
                    }
                    return date.format('h A');
                  }
                }
              }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                console.log("Look, tooltipItem! ", tooltipItem);
                console.log("Look, data! ", data);
                console.log("Look, real stuff! ", data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x);
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ' : ';
                }
                label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].prescriptionId + " :: "
                label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x + " @ "
                label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].yA
                //label += moment(tooltipItem.yLabel).format("hh:mm A");
                return label;
              }
            }
          }
      }
    })
  }
  return (
    <div>
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}