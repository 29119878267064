import axios from "axios";
import endpoints from "./endpoints";

/**
 * Generic Ajax Request
 * Params: {
 *  resource: String
 *  method: String
 *  data: Object
 * },
 * user: Object (contains: token, client & uid)
 */
export const api = (request, user, adminEndpoint=false) => {
  // Defaults & Overrides
  let resource = request.resource,
    method = request.method ? request.method : "get",
    data = request.data ? request.data : null,
    contentType = request.contentType ? request.contentType : "application/json";

  // Set URL & Default Headers
  let url;
  if (adminEndpoint) {
    url = `${endpoints.api}/${resource}`;
  } else {
    url = `${endpoints.api}/api/${resource}`;
  }
  let headers = { "Content-Type": contentType };
console.log("API: user making request: ", user);
console.log("Content type: ", contentType);
  // Append User Authentication Data
  if (user) {
    headers["access-token"] = user.token;
    headers["client"] = user.client;
    headers["uid"] = user.uid;
  }

  // Create the config object
  let config = data ? { url, method, headers, data } : { url, method, headers };

  // Run the request
  return axios(config)
    .then(response => response)
    .catch(error => {
      throw error.response;
    });
};
