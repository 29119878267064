import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory, useLocation } from "react-router-dom";

import Login from "./Login";
import App from "./App";
import License from "./pages/License";
import Privacy from "./pages/Privacy";
//import classes from "*.module.scss";
import { makeStyles } from '@material-ui/core/styles';

import {getAccessToken, authenticate, isAuthenticated} from './utils/authenticate';
import { normalizeUser } from './utils/helpers';

import Cookies from 'js-cookie';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    }
}));

/*const sessionAuth = {
  isAuthenticated: false,
  authenticate(cb){
    sessionAuth.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb){
    sessionAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
}*/

/*const getAccessToken = () => Cookies.get('access-token');
const isAuthenticated = () => !!getAccessToken();*/
/*function getAccessToken() {
  return Cookies.get('access-token')
};
function isAuthenticated() {
  return !!getAccessToken();
  //return false;
};*/

function PrivateRoute({ children, ...rest}) {
  console.log("isAuthenticated?!? ", isAuthenticated());
  return (
    <Route
       {...rest}
       render={({ location }) =>
        //sessionAuth.isAuthenticated
        isAuthenticated() ? (
        children
        ) : (
          <Redirect
              to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function AppRouter() {
    const classes = useStyles();
  return (
    <Router>
      <div className={classes.root}>
        <Route path="/" exact component={Login} />
        <Route path="/license" exact component={License} />
        <Route path="/privacy" exact component={Privacy} />
        <PrivateRoute path="/dashboard">
          <App />
        </PrivateRoute>
      </div>
    </Router>
  );
}

export default AppRouter;