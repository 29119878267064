import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


import DateFnsUtils from '@date-io/date-fns';

import Cookies from 'js-cookie';

import moment from 'moment';

// Get the API
import { api } from "./api/api";

// Get the data table
import DeviceLogTable from './DeviceLogTable';
import LogReportTable from './LogReportTable';
import LogReportChart from './LogReportChart';
import { PlayCircleFilledWhite } from '@material-ui/icons';
import DeviceTracking from './DeviceTracking';

// Get cookie stuff
// TODO move cookie stuff to Redux?
const userCookie = Cookies.get('iscpUser');
const user = userCookie ? JSON.parse(userCookie) : "";

// Get data function
function getDataRows(reportStartDate="", reportEndDate=""){
    return new Promise(function(resolve, reject){
      try {
        let curCookie = userCookie ? userCookie : Cookies.get('iscpUser');
        console.log("curCookie: ", curCookie);
        let curUser = user ? user : JSON.parse(curCookie);
        console.log("User making request: ", curUser);
        let resourceURI = "customer_data"
        if (reportStartDate) {
          resourceURI += '?startDate=' + encodeURIComponent(reportStartDate);
          if (reportEndDate){
            resourceURI += '&endDate=' + encodeURIComponent(moment(reportEndDate+" 00:00:00").add(1, 'days').format("MM/DD/YYYY"));
          }
        } else {
          if (reportEndDate){
            resourceURI += '?endDate=' + encodeURIComponent(moment(reportEndDate+" 00:00:00").add(1, 'days').format("MM/DD/YYYY"));
          }
        }
        console.log("The requested resource: ", resourceURI);
        const request = {
          //resource: "customer_data",
          resource: resourceURI,
          method: "get"
        };
        api(request, curUser).then(response => {
          if (response.status === 200){
          console.log("Response: ", response.data.customer_data);
          resolve(response.data.customer_data);
        } else {
          console.log("Login error: ", response);
          reject(response);
        }
        });
      } catch (error) {
        console.log("Error: ", error);
        reject(error);
      }
    })
  };

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabBar: {
        //backGroundColor: theme.palette.secondary,
        backGroundColor: '#ec4fbd'
    },
    toolbar: {
      backgroundColor: '#82005f',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    loginButtonUpdate: {
      backgroundColor: '#ec4fbd',
      color: 'white',
      '&:hover': {
        backgroundColor: '#29c574',
        color: '#FFF'
      }
    },
    loginButton: {
      backgroundColor: '#ba2190',
      color: 'white',
      '&:hover': {
        backgroundColor: '#29c574',
        color: '#FFF'
      }
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
  }));

  /* patient helpers */
  function getPatientIdentifier(row){
    return row.patient_identifier;
  };

  /* Styling for patient selector */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

/* Filter for unique patient ids */
function onlyUnique(value, index, self) { 
  return self.indexOf(value) === index;
};

/* Generate random rgb color */
function getRandomColorRgba() {
	let red = Math.floor(Math.random() * 256);
	let green = Math.floor(Math.random() * 256);
	let blue = Math.floor(Math.random() * 256);
	return `rgba(${red}, ${green}, ${blue}, 1)`;
}
  
  export default function DataNavigator() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [deviceLogs, setDeviceLogs] = React.useState([]);
    const [fetchingData, setFetchingData] = React.useState(true);
    const [selectedPatients, setSelectedPatients] = React.useState([]);
    const [filteredDeviceLogs, setFilteredDeviceLogs] = React.useState([]);
    const [filteredDeviceLogsSeries, setFilteredDeviceLogsSeries] = React.useState([]);
    const [dataRefreshNeeded, setDataRefreshNeeded] = React.useState(false);
    const [xAxisLablesList, setXAxisLabelsList] = React.useState([]);
    const [dataMayBeOld, setDataMayBeOld] = React.useState(false);
    const [selectAllPatientsNeeded, setSelectAllPatientsNeeded] = React.useState(false);
    const [selectNoPatientsNeeded, setSelectNoPatientsNeeded] = React.useState(false);
  
    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(30, 'd').format("MM/DD/YYYY"));
    const [reportEndDate, setReportEndDate] = React.useState(moment().format("MM/DD/YYYY"));

    const [patientIds, setPatientIds] = React.useState([]);
    const [patientIdsForReport, setPatientIdsForReport] = React.useState([]);
  
    const checkForPatientIdsForReportChange = event => {
      setPatientIdsForReport(event.target.value);
      console.log("Ids for report: ", event.target.value);
      setSelectedPatients(event.target.value);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const seriesColors = [
      'rgba(197,41,159,1)',
      'rgba(77,34,186,1)',
      'rgba(34,186,118,1)',
      'rgba(186,130,34,1)',
      'rgba(143,186,118,1)',
      'rgba(0,0,0,1)',
    ];


    React.useEffect(() => {
        // take the selected Patients and filter the 
        // main data set on the patient id from the
        // selected Patients array
        if (selectedPatients.length === 0){
            setFilteredDeviceLogs([]);
        } else {
            setFilteredDeviceLogs(
                deviceLogs.filter(({patient_identifier}) => selectedPatients.includes(patient_identifier))
            )
        }
    }, [selectedPatients]);
    
    React.useEffect(() => {
      // take the selected Patients and filter the 
      // main data set on the patient id from the
      // selected Patients array
      if (selectAllPatientsNeeded){
        setPatientIdsForReport(patientIds);
        setSelectedPatients(patientIds);
        setSelectAllPatientsNeeded(false);
      }
    }, [selectAllPatientsNeeded]);

    React.useEffect(() => {
      // take the selected Patients and filter the 
      // main data set on the patient id from the
      // selected Patients array
      if (selectNoPatientsNeeded){
        setPatientIdsForReport([]);
        setSelectedPatients([]);
        setSelectNoPatientsNeeded(false);
      }
    }, [selectNoPatientsNeeded]);

    React.useEffect(() => {
        if (filteredDeviceLogs.length === 0) {
            setFilteredDeviceLogsSeries([]);
        } else {
            // we need to take the selectedPatients and then
            // filter out everything except 
            // for each one, generate a series and extract
            // out the date, time, and # of pills dispensed (to start.)
            setFilteredDeviceLogsSeries(() => {
                let logSeries = {};
                logSeries.startDate = reportStartDate;
                logSeries.endDate = reportEndDate;
                logSeries.dataSets = [];
                  selectedPatients.forEach( (patient, index) => {
                    // get the patient id, initialize a new series object,
                    // set the values...
                    let series = {};
                    series.patient_identifier = patient;
                    series.label = patient;
                    series.radius = 3;
                    series.backgroundColor = index <= 6 ? seriesColors[index] : getRandomColorRgba();
                    series.borderColor = index <= 6 ? seriesColors[index] : getRandomColorRgba();
                    
                    series.data = deviceLogs.filter(
                        ({patient_identifier, parselogentry}) => 
                            (patient == patient_identifier) &&
                            parselogentry.includes('Delivery// Success')
                        ).map(log => {
                          console.log("Patient: ", series.patient_identifier);
                            let seriesEntry = {};
                            seriesEntry.prescriptionId = log.prescription_id;
                            seriesEntry.x = log.eventdate;
                            seriesEntry.y = log.eventDateUTC.local().year(2000).month(0).date(1).format("x");
                            seriesEntry.yA = log.eventtime;
                            return seriesEntry;
                        });
                    logSeries.dataSets.push(series);
                });
                setXAxisLabelsList(() => {
                  let datesInSeries = [];
                  if (reportStartDate && reportEndDate) {
                    var currDate = moment(reportStartDate+" 00:00:00").startOf('day');
                    var lastDate = moment(reportEndDate+" 23:59:59").add(1, 'day').startOf('day');
                    datesInSeries.push(currDate.format("MM/DD"));
                    while(currDate.add(1, 'days').diff(lastDate) < 0) {
                        datesInSeries.push(currDate.format("MM/DD"));
                    }
                    console.log("The dates: ", datesInSeries);
                    return datesInSeries;
                  } else {
                    return [];
                  }
                });
                console.log("SERIES: ", logSeries);
                return logSeries;
            })
        }
    }, [filteredDeviceLogs]);
  
    React.useEffect(() => {
        console.log('componentDidMount');
        getDataRows(reportStartDate, reportEndDate)
          .then(data => {
              let processedData = data.map( function(event) {
                let processedEvent = event;
                if (!event.parselogentry.includes('Delivery// Success')) {
                    processedEvent.pillcount = '-';
                }
                let theDate = new Date(event.event_time);
                let eventdate = theDate.toLocaleDateString([], {timeZone: 'America/New_York', month: '2-digit', day: '2-digit'});
                let eventdateTable = theDate.toLocaleDateString([], {timeZone: 'America/New_York', month: '2-digit', day: '2-digit', year: 'numeric'});
                let eventtime = theDate.toLocaleTimeString([], {timeZone: 'America/New_York', hour: '2-digit', minute:'2-digit', hour12: true});
                let momentDate = new moment.utc(event.event_time);
                processedEvent.eventdate = eventdate;
                processedEvent.eventdateTable = eventdateTable;
                processedEvent.eventtime = eventtime;
                processedEvent.eventDateUTC = momentDate;
                return processedEvent;
              });
              setDeviceLogs(processedData);
              const patientIds = processedData.map(getPatientIdentifier).filter( onlyUnique);
              setPatientIds(patientIds);
              setPatientIdsForReport(patientIds);
              setSelectedPatients(patientIds);
              console.log("Patient IDs: ", patientIds);
              setFetchingData(false);
            })
      }, []);

      React.useEffect(() => {
        console.log('Data refresh needed');
        if (dataRefreshNeeded){
        getDataRows(reportStartDate, reportEndDate)
          .then(data => {
              let processedData = data.map( function(event) {
                let processedEvent = event;
                if (!event.parselogentry.includes('Delivery// Success')) {
                    processedEvent.pillcount = '-';
                }
                let theDate = new Date(event.event_time);
                let eventdate = theDate.toLocaleDateString([], {timeZone: 'America/New_York', month: '2-digit', day: '2-digit'});
                let eventdateTable = theDate.toLocaleDateString([], {timeZone: 'America/New_York', month: '2-digit', day: '2-digit', year: 'numeric'});
                let eventtime = theDate.toLocaleTimeString([], {timeZone: 'America/New_York', hour: '2-digit', minute:'2-digit', hour12: true});
                let momentDate = new moment.utc(event.event_time);
                processedEvent.eventdate = eventdate;
                processedEvent.eventdateTable = eventdateTable;
                processedEvent.eventtime = eventtime;
                processedEvent.eventDateUTC = momentDate;
                return processedEvent;
              });
              setDeviceLogs(processedData);
              const patientIds = processedData.map(getPatientIdentifier).filter( onlyUnique);
              setPatientIds(patientIds);
              setPatientIdsForReport(patientIds);
              setSelectedPatients(patientIds);
              console.log("Patient IDs: ", patientIds);
              setFetchingData(false);
              setDataRefreshNeeded(false);
              setDataMayBeOld(false);
            })
          }
        }, [dataRefreshNeeded]);
      
      const handleReportStartDateChange = date => {
        let newStartDate = moment(date);
        let endDate = moment(reportEndDate);
        setReportStartDate(moment(date).format("MM/DD/YYYY"));
        if (newStartDate.diff(endDate, 'days') > 0){
          console.log("startDate after end date... fix it!", newStartDate.diff(endDate, 'days'));
          setReportEndDate(newStartDate.add(30, 'days').format("MM/DD/YYYY"));
        } else if (newStartDate.diff(endDate,'days') < -60){
          console.log("startDate > 60 days before end date... fix it!", newStartDate.diff(endDate,'days'));
          setReportEndDate(newStartDate.add(60, 'days').format("MM/DD/YYYY"));
        } else {
          console.log("Here: ", newStartDate.diff(endDate, 'days'));
          console.log("Everything looks OK!");
        }
        console.log("New report start date: ", reportStartDate);
        setDataMayBeOld(true);
      };

      const handleReportEndDateChange = date => {
        let newEndDate = moment(date);
        let startDate = moment(reportStartDate);
        setReportEndDate(newEndDate.format("MM/DD/YYYY"));
        if (startDate.diff(newEndDate, 'days') > 0){
          console.log("startDate after end date... fix it!", startDate.diff(newEndDate, 'days'));
          setReportStartDate(newEndDate.subtract(30, 'days').format("MM/DD/YYYY"));
        } else if (startDate.diff(newEndDate,'days') < -60){
          console.log("startDate > 60 days before end date... fix it!", startDate.diff(newEndDate,'days'));
          setReportStartDate(newEndDate.subtract(60, 'days').format("MM/DD/YYYY"));
        } else {
          console.log("Here: ", startDate.diff(newEndDate, 'days'));
          console.log("Everything looks OK!");
        }
        console.log("New report end date: ", reportEndDate);
        setDataMayBeOld(true);
      };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="reporting tabs" className={classes.toolbar}>
              <Tab label="Device Logs" {...a11yProps(0)} />
              <Tab label="Report Chart" {...a11yProps(1)} />
              <Tab label="Device Tracking" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <form className={classes.form} noValidate id="reportGeneratorForm">
            <Box visibility={value === 2 ? "hidden" : "visible"}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={reportStartDate}
                    onChange={handleReportStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={reportEndDate}
                    onChange={handleReportEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                  />
                </Grid>
                <Grid item xs={3} style={{marginTop:20}}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{dataMayBeOld ? 'Refresh suggested' : 'Refresh available'}</Typography>
                        {dataMayBeOld ? "You've recently changed the date range on the logs. You should probably refresh the data to pick up any changes!" :
                      "Your data should be up-to-date, but you can always refresh to make sure you aren't missing anything!"}
                      </React.Fragment>
                    }>
                    <Button
                      type="button"
                      variant="contained"
                      className={dataMayBeOld ? classes.loginButtonUpdate : classes.loginButton}
                      startIcon={dataMayBeOld ? <RefreshIcon /> : <CheckCircleIcon /> }
                      
                      onClick={() => { setDataRefreshNeeded(true)
                      }}
                    >
                      { dataMayBeOld ? "Click To Refresh" : "Refresh Log List" }
                    </Button>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-patients">Patients</InputLabel>
                    <Select
                      multiple
                      value={patientIdsForReport}
                      onChange={checkForPatientIdsForReportChange}
                      input={<Input id="select-patients" />}
                      renderValue={selected => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {patientIds.map(patient => (
                        <MenuItem key={patient} value={patient}>
                          <Checkbox checked={patientIdsForReport.indexOf(patient) > -1} />
                          <ListItemText primary={patient} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Select All Patients</Typography>
                      </React.Fragment>
                    }>
                      <IconButton
                      type="button"
                      variant="contained"
                      onClick={() => { setSelectAllPatientsNeeded(true)
                      }}
                    >
                      <CheckBoxIcon />
                    </IconButton>
                    </HtmlTooltip>
                    <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">Deselect All Patients</Typography>
                      </React.Fragment>
                    }>
                      <IconButton
                      type="button"
                      variant="contained"
                      onClick={() => { setSelectNoPatientsNeeded(true)
                      }}
                    >
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                    </HtmlTooltip>
                </Grid>
              </Grid>
            </Box>
          </form>
          <TabPanel value={value} index={0}>
            <DeviceLogTable deviceLogs={filteredDeviceLogs} isFetchingData={fetchingData} patientSelectionHandler={setSelectedPatients} />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <LogReportChart dataSeries={filteredDeviceLogsSeries} xAxisLablesList={xAxisLablesList} />
          </TabPanel>
          <TabPanel value={value} index={2}>
              <DeviceTracking></DeviceTracking>
          </TabPanel>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  
