import React from 'react';
//import logo from './logo.svg';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import Signin from './Signin';
import Copyright from './Copyright';
import OutsideFooterLinks from './OutsideFooterLinks';



function Login() {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Signin />
        <br />
        <br />
        <br />
        <br />
        <br />
        <OutsideFooterLinks /><br />
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;
