import React from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from './IS_Brand_color.png';
import tadLogo from './tad-portal-logo.png';

import Cookies from 'js-cookie';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';

//import { Router } from 'react-router'; 

import {getAccessToken, authenticate, isAuthenticated} from './utils/authenticate';
import { normalizeUser } from './utils/helpers';
//import { createLocalUser } from "../../api/storage";
import { api } from "./api/api";
//import { deleteLocalUser } from "../../api/storage";
import { miniset } from "./utils/helpers";
import { NONAME } from 'dns';


const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loginMessageNormal: {
    display: 'none',
  },
  loginMessageError: {
    dipslay: 'block',
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailListed, setEmailListed] = React.useState(false);
  const [passwordListed, setPasswordListed] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  const handleEmailChange = (event) => {
    const newVal = event.target.value;
    newVal.length > 0 ? setEmailListed(true) : setEmailListed(false);
  };
  const handlePasswordChange = (event) => {
    const newVal = event.target.value;
    newVal.length > 0 ? setPasswordListed(true) : setPasswordListed(false);
  };
  const handleTermsChange = () => {
   setTermsAccepted(!termsAccepted); 
  };
  const loginMessageClassname = clsx({
    [classes.loginMessageError]: error,
  });
  const LoginMessageDisplay = () => {
    return (
      <div>
        <Typography component="h1" variant="h6" color="error" className={loginMessageClassname} >{errorMessage}</Typography> 
      </div>
    )
  };
  const LoginButton = () => {
    let history = useHistory();

    const handleLoginClick = () => {
      try {
        setSuccess(false);
        setLoading(true);
        setError(false);
        //const data = miniset(["email", "password"], {email: document.getElementById("email").value, password: document.getElementById("password").value});
        var FD = new FormData();
        FD.append("email", document.getElementById("email").value);
        FD.append("password", document.getElementById("password").value);
        console.log("THE DATA: ", FD);
        const request = {
          resource: "pharmacist/auth/sign_in",
          method: "post",
          contentType: "multipart/form-data",
          data: FD
        };
        //const response = api(request);
        api(request).then(response => {
          if (response.status === 200){
            console.log("Response: ", response);
            console.log("Access Token: ", response.headers["access-token"]);
            
            var user = normalizeUser(response);
            const expires = (60 * 60 * 24) * 1000;
            const inOneHour = new Date(new Date().getTime() + expires);
            Cookies.set('iscpUser', user, { expires: inOneHour });
            console.log('user:', user);
            setSuccess(true);
            setLoading(false);
            history.push('/dashboard');
            //window.location.replace('/dashboard');
            /* yield all([
            put({ type: "user/success", payload: response }),
            put({ type: "user/ready" })
            ]) */
            //yield createLocalUser(response);
            return false;
          } else {
            console.log("Login error: ", response);
            console.log("Error text: ", response.data.data.errors[0]);
            setError(true);
            setLoading(false);
            const errorMessage = response.data.data.errors[0];
            setErrorMessage(errorMessage);
            return false;
          }
        })
        .catch(response => {
          console.log("Login error: ", response);
          setError(true);
          setLoading(false);
          setErrorMessage(response.data.errors[0]);
          return false;
        });
      } catch (error) {
        console.log("Error: ", error);
        setError(true);
        setErrorMessage(error);
        return false;
        //yield put({ type: "user/error", payload: error });
      }
    };
    return (
      <div className={classes.wrapper}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={!(emailListed && passwordListed && termsAccepted)}
              onClick={handleLoginClick}
            >
              Sign In
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
    )
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Typography variant="h3" component="h1" gutterBottom>
        <img src={logo} alt="Intent Solutions" width={'330'} />
        </Typography>
        <br />
        <Typography variant="h5" component="h2">
        <img src={tadLogo} alt="tadPortal ™" width={'330'} />
        </Typography>
        <br />
        <br />
        <br />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate id="loginForm">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          <FormControlLabel
            control={
            <Checkbox
              value="remember"
              required
              color="primary"
              onChange={handleTermsChange}
            />}
            label="I agree to the terms and conditions."
          />
          <LoginButton />
          <LoginMessageDisplay />
          <Grid container style={{display:'none'}}>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}