import Cookies from 'js-cookie';
//export const getAccessToken = () => Cookies.get('access-token');
export const getAccessToken = () => Cookies.get('iscpUser');
//export const getRefreshToken = () => Cookies.get('refresh_token');
export const isAuthenticated = () => !!getAccessToken();

export const authenticate = async () => {
    if (getAccessToken()) {
      try {
        //const tokens = await refreshTokens() // call an API, returns tokens
  
        //const expires = (tokens.expires_in || 60 * 60) * 1000
        //TODO - put in real expiration, not just 1 hour
        const expires = (60 * 60) * 1000;
        const inOneHour = new Date(new Date().getTime() + expires)
  
        // you will have the exact same setters in your Login page/app too
        //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
        //Cookies.set('refresh_token', tokens.refresh_token)
        Cookies.set('user', )
        return true
      } catch (error) {
        redirectToLogin()
        return false
      }
    }
  
    redirectToLogin()
    return false
  }

  const redirectToLogin = () => {
    window.location.replace(
      //`${getConfig().LOGIN_URL}?next=${window.location.href}`
      `/?next=${window.location.href}`
    )
    // or history.push('/login') if your Login page is inside the same app
  } 
/*
  const logout = () => {
    Cookies.set
  }*/