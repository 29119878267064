import React from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import Grid from '@material-ui/core/Grid';

import { api } from "./api/api";

// Material Table
import MaterialTable from 'material-table';
import { AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight,
Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage,
Remove, SaveAlt, Search, ViewColumn, NoEncryption } from "@material-ui/icons";
import { NONAME } from 'dns';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
// Material Table


//import rows from './customerdata.json';
const userCookie = Cookies.get('iscpUser');
const user = userCookie ? JSON.parse(userCookie) : "";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function getPrescription(){
  try {
    console.log("User making request: ", user);
    const request = {
      resource: "pharmacist/prescription/1751",
      method: "get"
    };
    //const response = api(request);
    api(request, user).then(response => {
      if (response.status === 200){
      console.log("Response: ", response.data);
      /* yield all([
      put({ type: "user/success", payload: response }),
      put({ type: "user/ready" })
      ]) */
      return response.data;
    } else {
      console.log("Login error: ", response);
      return response;
    }
    });
  } catch (error) {
    console.log("Error: ", error);
    return false;
    //yield put({ type: "user/error", payload: error });
  }
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  gridRoot: {
    flexGrow: 1,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    maxHeight: 500,
    overflowX: 'auto',
  },
  hidden: {
    display: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: 'white'
  }
}));

export default function DeviceLogTable(props) {
  const classes = useStyles2();
  const [rows, setRows] = React.useState([]);
  //const [isFetchingData, setFetchingData] = React.useState(true);

  React.useEffect(() => {
    console.log('componentDidMount');
    //Possibly do stuff to get prescriptions?!?
  }, [props.deviceLogs]);

  React.useEffect(() => {
    console.log('Update to selected patients!', props.selectedPatients);
  }, [props.selectedPatients]);

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        columns={[
          //{ title: "Log Entry Id", field: "log_entry_id", type: "numeric" },
          { title: "Patient Group", field: "patient_group" },
          { title: "Patient ID", field: "patient_identifier", type: "numeric" },
          { title: "Prescription Ref #", field: "prescription_id", type: "numeric" },
          { title: "Log ID", field: "log_entry_id", type: "numeric" },
          { title: "Event", field: "parselogentry" },
          { title: "Event Date", field: "eventdate", type: "date" },
          { title: "Event Time", field: "eventtime", type: "time" },
          { title: "Pill Count", field: "pillcount", type: "numeric" }
        ]}
        data={props.deviceLogs}
        detailPanel={rowData => {
          return (
            <div
              className={classes.gridRoot}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                    
                        Regimen name:<br />
                        Description:<br />
                        Type:<br />
                        Pill per day: {rowData.pills_per_dose * rowData.doses_per_day}<br />
                        Pill per dose: {rowData.pills_per_dose}<br />
                        Doses per day: {rowData.doses_per_day}<br />
                        Minutes between doses:<br />
                        Customizable:<br />
                      
                      </Grid>
                      <Grid item xs={4}>
                        
                          Pill Count: {rowData.pillcount}<br /> 
                          <br />
                          Device:<br />
                        
                      </Grid>
                      <Grid item xs={4}>
                        
                          Medication<br />
                          Name:<br />
                          Strength:<br />
                          Capsule size:<br />
                        
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )
        }}
        title="Device Logs"
        options={{
          filtering: true,
          pageSize: 10,
          headerStyle: {
            textAlign: 'center'
          },
          rowStyle: {
            fontSize: 10,
          },
          exportButton: true,
          exportAllData: true,
          showTextRowsSelected: false,
        }}
        isLoading={props.isFetchingData}
      />
      <br /> 
      <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.hidden}
        onClick={() => {
          getPrescription()
          //.then(data => {
          //  console.log("DATA! ", data);
          //})
        }}
      >
        Get Data
      </Button>
    </div>
  );
}