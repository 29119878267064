import React from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import Link from '@material-ui/core/Link';
import Copyright from './Copyright';
import customerData from './customerdata.json';

import { makeStyles } from '@material-ui/core/styles';

import SiteMenu from './SiteMenu';

//import CustomerDataTable from './CustomerDataTable';
//import CustomerDataTableMaterial from './CustomerDataTableMaterial';
import DataNavigator from './DataNavigator';

import Cookies from 'js-cookie';

import logo from './IS_Brand_color.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const user = Cookies.get('iscpUser');

function App() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <SiteMenu userName="{user.email}"/>
      <Container maxWidth="lg">
        <Box my={4}>
          <DataNavigator />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Copyright />
        </Box>
      </Container>
    </Container>
  );
}

export default App;
