import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/paper';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CenterFocusStrong } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      //justify: center,
    },
    paper: {
      height: 50,
      width: 100,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center"
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

export default function OutsideFooterLinks() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} justify="center" spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item justify="center" textAlign="center" xs={3}>
          <Paper elevation="0" className={classes.paper}>
            <Link to="/license">Terms and Conditions</Link>
          </Paper>
        </Grid>
        <Grid item justify="center" xs={3}>
          <Paper elevation="0" className={classes.paper}>
            <Link to="/privacy">Privacy Policy</Link>
          </Paper>
        </Grid>
        <Grid item xs={3}></Grid>
    </Grid>
  )
}